import React from 'react';
import { graphql } from 'gatsby';
import clsx from 'clsx';

// import cleanCockpitData from 'Utils/cleanCockpitData';
import Metadata from '../components/Metadata';

import * as styles from './downloads.module.css';

import pdfIcon from '../assets/images/flatiron-file-type-icons/png/pdf.png';
import aiIcon from '../assets/images/flatiron-file-type-icons/png/ai.png';
import docIcon from '../assets/images/flatiron-file-type-icons/png/doc.png';
import jpgIcon from '../assets/images/flatiron-file-type-icons/png/jpg.png';
import pngIcon from '../assets/images/flatiron-file-type-icons/png/png.png';
import pptIcon from '../assets/images/flatiron-file-type-icons/png/ppt.png';
import zipIcon from '../assets/images/flatiron-file-type-icons/png/zip.png';
import DefaultLayout from '../layout/Default';
import HeaderImage from '../blocks/HeaderImage';

const fileTypeMapping = {
  pdf: pdfIcon,
  ai: aiIcon,
  doc: docIcon,
  jpg: jpgIcon,
  png: pngIcon,
  ppt: pptIcon,
  zip: zipIcon,
};

const AGBPage = ({ data, location }) => {
  const { siteData, pageData, categories, downloads } = data;
  const { title: siteTitle, canonicalSiteUrl } = siteData.siteMetadata;
  const { content, header, metadata, opengraph } = pageData;
  const { title, text, pageID } = content;

  const downloadsByCategory = {};
  downloads.edges.forEach(({ node: download }) => {
    if (download.published && download.category && download.category.id) {
      downloadsByCategory[download.category.id] = downloadsByCategory[download.category.id] || [];
      downloadsByCategory[download.category.id].push(download);
    }
  });

  return (
    <DefaultLayout>
      <HeaderImage data={header} minHeight={150} />
      <div className={clsx(styles.Downloads, 'defaultWidth')} id={pageID}>
        <div>
          <h2>{title}</h2>
          <div className="textPage" dangerouslySetInnerHTML={{ __html: text }} />
        </div>
        {categories.edges.map(({ node: category }) => {
          if (!category.published) {
            return null;
          }
          const { id, title: categoryTitle, text: categoryText } = category;
          const downloadsForCategory = downloadsByCategory[id];

          if (
            (!downloadsForCategory && !categoryText) ||
            !['Einkaufsbedingungen', 'Lieferbedingungen', 'Delivery conditions', 'Purchase conditions'].includes(
              categoryTitle,
            )
          ) {
            return null;
          }
          return (
            <section key={categoryTitle}>
              <h3>{categoryTitle}</h3>
              <div dangerouslySetInnerHTML={{ __html: categoryText }} />
              {downloadsForCategory && Array.isArray(downloadsForCategory) && (
                <ul style={{ paddingLeft: 10 }}>
                  {downloadsForCategory.map(({ label, file }) => (
                    <li
                      key={file.publicURL}
                      style={{
                        listStyleType: 'none',
                        background: `transparent url('${fileTypeMapping[file.extension]}') no-repeat`,
                        backgroundSize: 'contain',
                        paddingLeft: 35,
                        marginTop: 10,
                      }}
                    >
                      <a href={file.publicURL} target="_blank" rel="noopener noreferrer">
                        {label}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </section>
          );
        })}
      </div>
      <Metadata
        data={metadata}
        opengraph={opengraph}
        defaultTitle={`${title} : ${siteTitle}`}
        canonicalSiteUrl={canonicalSiteUrl}
        path={location.pathname}
      />
    </DefaultLayout>
  );
};

export default /* cleanCockpitData( */ AGBPage /* ) */;

export const AGBPageQuery = graphql`
  query AGBPageQuery($language: String) {
    siteData: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    pageData: cockpitPageAgb(lang: { eq: $language }) {
      content {
        title
        pageID
      }

      header {
        id
        line1
        line2
        textPosition
        published
        linkTarget
        linkLabel
        madeInAustriaOverlay
        image {
          processed: childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1600, placeholder: NONE)
          }
        }
        mobileImage {
          processed: childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 900, placeholder: NONE)
          }
        }
      }

      metadata {
        title
        metakeywords
        metadescription
      }

      opengraph {
        title
        description
        type
        image {
          childImageSharp {
            fixed(width: 1200, height: 630) {
              src
              width
              height
              aspectRatio
              originalName
            }
          }
        }
      }
    }

    categories: allCockpitDownloadCategories(filter: { lang: { eq: $language } }, sort: { fields: order, order: ASC }) {
      edges {
        node {
          id
          published
          title
          text
        }
      }
    }

    downloads: allCockpitDownloads(filter: { lang: { eq: $language } }) {
      edges {
        node {
          id
          label
          published
          category {
            id
          }
          file {
            id
            absolutePath
            publicURL
            extension
          }
        }
      }
    }
  }
`;
